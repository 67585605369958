import * as React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import Text from "../../components/text"
import Square from "../../components/square"
import { SimpleTextLink } from "../../components/link"
import { color, space } from "../../theme"

export type Block2Props = {
  squares: { color1: ColorKeys; color2: ColorKeys; copy: string; to: string }[]
}
export type Props = {
  data: Block2Props
  options?: {}
}

const Border = styled.div`
  display: none;
  ${up("desktop")} {
    display: grid;
  }
  border-top: 1px solid ${color("lightgrey")};
  border-bottom: 1px solid ${color("lightgrey")};
`

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: stretch;
  padding: ${space("large")} 0;
  margin: 0 auto;
  ${up("tablet")} {
    grid-template-columns: 768px;
  }
  ${up("desktop")} {
    grid-template-columns: 1280px;
  }
`

const Container = styled.div<{ size: number }>`
  display: grid;
  grid-template-columns: repeat(${p => p.size}, 1fr);
  align-items: flex-start;
  justify-items: flex-start;
  justify-content: space-evenly;
  grid-gap: ${space("large")};
`

const Item = styled(SimpleTextLink)`
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: flex-start;
  justify-items: flex-start;
`
const Block2: React.FC<Props> = ({ data, options }) => {
  return (
    <Border>
      <Wrapper>
        <Container size={data.squares.length}>
          {data.squares.map((square, idx) => (
            <Item
              key={`homepage-block2-${idx}`}
              to={square.to}
              underlined
              hoverColor="blue"
              align="left"
            >
              <Square colors={[square.color1, square.color2]} />
              {square.copy}
            </Item>
          ))}
        </Container>
      </Wrapper>
    </Border>
  )
}

export default Block2
