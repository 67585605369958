import React from "react"
import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"
import useIsInViewport from "use-is-in-viewport"
import Matter, {
  Engine,
  Render,
  World,
  Bodies,
  Mouse,
  MouseConstraint,
} from "matter-js"

const Container = styled.div<{ dpr: number }>`
  width: 100%;
  height: auto;
  ${up("tablet")} {
    width: 360px;
    height: 510px;
  }
`

const sun = (dpr: number) =>
  // @ts-ignore
  Bodies.circle(115, -40, 75, {
    render: {
      sprite: {
        xScale: 1 / dpr,
        yScale: 1 / dpr,
        texture: `https://paybase.imgix.net/assets/yellow.png?w=150&h=150&dpr=${dpr}&auto=enhance`,
      },
    },
  })

const cube = (dpr: number) =>
  // @ts-ignore
  Bodies.rectangle(100, -100, 100, 100, {
    render: {
      sprite: {
        xScale: 1 / dpr,
        yScale: 1 / dpr,
        texture: `https://paybase.imgix.net/assets/red.png?w=100&h=100&dpr=${dpr}&auto=enhance`,
      },
    },
  })

const green = (dpr: number) =>
  // @ts-ignore
  Bodies.polygon(250, -30, 5, 75, {
    render: {
      sprite: {
        xScale: 1 / dpr,
        yScale: 1 / dpr,
        texture: `https://paybase.imgix.net/assets/green.png?w=136&h=144&dpr=${dpr}&auto=enhance`,
      },
    },
  })
const illuminati = (dpr: number) =>
  // @ts-ignore
  Bodies.polygon(168, 0, 3, 58, {
    render: {
      sprite: {
        xScale: 1 / dpr,
        yScale: 1 / dpr,
        texture: `https://paybase.imgix.net/assets/purple.png?w=86&h=100&dpr=${dpr}&auto=enhance`,
      },
    },
  })
const identity = (dpr: number) =>
  // @ts-ignore
  Bodies.rectangle(20, -150, 75, 58, {
    render: {
      sprite: {
        xScale: 1 / dpr,
        yScale: 1 / dpr,
        texture: `https://paybase.imgix.net/assets/blue.png?w=75&h=58&dpr=${dpr}&auto=enhance`,
      },
    },
  })

let engine: Matter.Engine
let render
let buffer: Matter.Body[]
let mouseConstraint: Matter.MouseConstraint

const Physics: React.FC<{ breakpoint: string }> = ({ breakpoint }) => {
  const ref = React.useRef(null)
  const [dpr, setDpr] = React.useState<number>(1)
  const [isInViewport, targetRef] = useIsInViewport({
    threshold: 150,
    target: ref,
  })
  const [loaded, setL] = React.useState(false)
  React.useEffect(() => {
    if (window) setDpr(window.devicePixelRatio)
  }, [])
  React.useEffect(() => {
    if (ref.current && !loaded && dpr) {
      if (!loaded) {
        engine = Engine.create({})
        render = Render.create({
          element: ref.current,
          engine: engine,
          options: {
            width: breakpoint === "mobile" ? window.innerWidth : 360,
            height: 510,
            wireframes: false,
            showAngleIndicator: false,
            background: "#ff88a4",
          },
        })
        Matter.Render.setPixelRatio(render, dpr || 1)
        World.add(engine.world, [
          Bodies.rectangle(0, 535, 720, 50, {
            isStatic: true,
          }),
          Bodies.rectangle(-25, 0, 50, 10000, {
            isStatic: true,
          }),
          Bodies.rectangle(
            breakpoint === "mobile" ? window.innerWidth + 25 : 385,
            0,
            50,
            10000,
            {
              isStatic: true,
            }
          ),
        ])

        const mouse = Mouse.create(render.canvas)
        // @ts-ignore
        mouse.element.removeEventListener("mousewheel", mouse.mousewheel)
        // @ts-ignore
        mouse.element.removeEventListener("DOMMouseScroll", mouse.mousewheel)
        // @ts-ignore
        mouseConstraint = MouseConstraint.create(engine, {
          mouse,
          constraint: {
            stiffness: 0.2,
            render: {
              visible: false,
            },
          },
        })
        Matter.Events.on(mouseConstraint, "mousedown", e => {
          const b = buffer.pop()
          if (b) World.add(engine.world, b)
        })
        buffer = [
          green(window.devicePixelRatio),
          cube(window.devicePixelRatio),
          sun(window.devicePixelRatio),
          illuminati(window.devicePixelRatio),
          identity(window.devicePixelRatio),
        ]
        Engine.run(engine)
        Render.run(render)
        setL(true)
      }
    }
    if (loaded && isInViewport && engine && dpr) {
      World.clear(engine.world, true)
      buffer = [
        green(window.devicePixelRatio),
        cube(window.devicePixelRatio),
        sun(window.devicePixelRatio),
        illuminati(window.devicePixelRatio),
        identity(window.devicePixelRatio),
      ]
      World.add(engine.world, mouseConstraint)
      World.add(engine.world, [
        green(dpr!),
        cube(dpr!),
        sun(dpr!),
        illuminati(dpr!),
        identity(dpr!),
      ])
    }
  }, [ref, isInViewport, breakpoint, loaded])
  return <Container ref={targetRef} dpr={dpr} />
}
export default Physics
