import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import BlogCard from "../Blog/BlogCard"

import Title from "../../components/title"
import { space } from "../../theme"

import { BlogProps } from "../Blog/utils"

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: stretch;
  padding-bottom: ${space("large")};
  padding-top: ${space("xlarge")};
  margin: 0 auto;
  ${up("tablet")} {
    padding: 0;
    grid-template-columns: 768px;
  }
  ${up("desktop")} {
    grid-template-columns: 1280px;
  }
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  ${up("desktop")} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  grid-gap: 1.5rem;
  align-items: flex-start;
  justify-items: center;
`

const Block6: React.FC = () => {
  const { allBlog } = useStaticQuery(
    graphql`
      query {
        allBlog(sort: { order: DESC, fields: published }, limit: 3) {
          nodes {
            title
            slug
            published
            author
            excerpt
            content
            cover {
              src
              alt
            }
            link {
              to
              copy
            }
            tags
          }
        }
      }
    `
  )
  return (
    <Wrapper>
      <Title copy="Latest Blogs" />
      <Container>
        {allBlog.nodes.map(
          (post: BlogProps & { index: number }, idx: number) => (
            <BlogCard key={`blog-card-${idx}`} {...post} index={idx} />
          )
        )}
      </Container>
    </Wrapper>
  )
}

export default Block6
