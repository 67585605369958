import * as React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { up, only } from "styled-breakpoints"

import Text from "../../components/text"
import { SquareIcon } from "../../components/icon"
import { color, space } from "../../theme"

export type BlockProps = {
  title: string
  copy: string
  icon: IconKeys
  iconBg: ColorKeys
  anchor: string
}

export type Block4Props = BlockProps[]

export type Props = {
  data: Block4Props
  options?: {}
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: stretch;
  margin: 0 auto;
  ${up("tablet")} {
    grid-template-columns: 768px;
  }
  ${up("desktop")} {
    grid-template-columns: 1280px;
  }
`

const BlocksContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;

  ${up("tablet")} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  ${up("desktop")} {
    grid-template-columns: repeat(4, 1fr);
  }
`
const BlockContainer = styled(Link)`
  text-decoration: none;
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-gap: 1.5rem;
  background-color: ${color("lavender")};
  border-bottom: 1px solid #d3c3de;
  &:nth-child(8) {
    border-bottom: none;
  }
  ${up("tablet")} {
    height: 357px;
    border-right: 1px solid #d3c3de;
  }
  ${only("tablet")} {
    &:nth-child(3),
    &:nth-child(6) {
      border-right: none;
    }
    &:nth-child(7),
    &:nth-child(8) {
      border-bottom: none;
    }
  }
  ${up("desktop")} {
    &:nth-child(1),
    &:nth-child(5) {
      border-left: 1px solid #d3c3de;
    }
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
      border-bottom: none;
    }
    /* &:nth-child(4),
    &:nth-child(8) {
      border-right: none;
    } */
  }
  padding: ${space("normal")};
  transition: background-color 0.3s ease-out;
  &:hover {
    background-color: ${color("yellow")};
  }
`
const Block: React.FC<BlockProps> = ({ title, copy, icon, iconBg, anchor }) => {
  return (
    <BlockContainer to={`/what-we-offer${anchor}`}>
      <SquareIcon name={icon} bg={iconBg} color="white" size="small" />
      <Text type="h5">{title}</Text>
      <Text>{copy}</Text>
    </BlockContainer>
  )
}

const Container = styled.div`
  position: relative;
  display: grid;
`

const BG = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${color("lavender")};
  z-index: -1;
`

const Block4: React.FC<Props> = ({ data, options }) => {
  return (
    <Container>
      <BG />
      <Wrapper>
        <BlocksContainer>
          {data.map((block, idx) => (
            <Block key={`block4-block-${idx}`} {...block} />
          ))}
        </BlocksContainer>
      </Wrapper>
    </Container>
  )
}

export default Block4
