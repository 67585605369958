import * as React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import Text from "../../components/text"
import { Link } from "../../components/link"
import Img from "../../components/img"
import { color, space } from "../../theme"

export type Block5Props = {
  title: string
  copy: string
  img: ImageType
  links: LinkType[]
}

export type Props = {
  data: Block5Props
  options?: {}
}

const Borders = styled.div`
  display: grid;
  border-bottom: 1px solid ${color("lightgrey")};
`

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: ${space("xlarge")} 0;
  padding-top: 0;
  justify-items: stretch;
  margin: 0 auto;
  ${up("tablet")} {
    padding-top: ${space("xlarge")};
    grid-template-columns: 768px;
  }
  ${up("desktop")} {
    padding-top: ${space("xlarge")};
    grid-template-columns: 1280px;
  }
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  ${up("desktop")} {
    grid-template-columns: 1fr 1fr;
  }
  align-items: center;
  justify-items: center;
`

const Content = styled.div`
  display: grid;
  grid-template-rows: repeat(3, min-content);
  grid-gap: ${space("normal")};
  align-items: center;
  justify-items: center;
  padding: ${space("normal")} ${space("large")};
  ${up("desktop")} {
    align-items: flex-start;
    justify-items: flex-start;
    padding: 0 ${space("xlarge")};
  }
`
const LinkContainer = styled.div<{ size: number }>`
  display: grid;
  grid-template-columns: repeat(${p => p.size || 1}, 1fr);
  grid-gap: 1rem;
`

const Block5: React.FC<Props> = ({ data, options }) => {
  return (
    <Borders>
      <Wrapper>
        <Container>
          <Img
            src={data.img.src}
            alt={data.img.alt}
            mw="100%"
            mh={490}
            tw={600}
            th={490}
            fit="contain"
          />
          <Content>
            <Text type="h4" color="black">
              {data.title}
            </Text>
            <Text color="grey">{data.copy}</Text>
            <LinkContainer size={data.links.length}>
              {data.links.map(({ to, copy, ...props }) => (
                <Link key={`block5-link-${to}`} to={to} {...props}>
                  {copy}
                </Link>
              ))}
            </LinkContainer>
          </Content>
        </Container>
      </Wrapper>
    </Borders>
  )
}

export default Block5
