import React from "react"
import lottie from "lottie-web"

const Animation: React.FC<{ path: string }> = ({ path, ...props }) => {
  const animationRef = React.createRef()
  const [loaded, setL] = React.useState(false)
  React.useEffect(() => {
    if (animationRef.current && !loaded) {
      lottie.loadAnimation({
        container: animationRef.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        path,
      })
      setL(true)
    }
  }, [animationRef])
  return <div {...props} ref={animationRef} />
}

export default Animation
