import * as React from "react"
import styled from "styled-components"

import { getColor } from "../theme"

const PP = styled.path<{ color: ColorKeys }>`
  fill: ${getColor};
`

export const Square: React.FC<{ colors: [ColorKeys, ColorKeys] }> = ({
  colors,
  ...props
}) => {
  return (
    <svg {...props} width="25px" height="25px">
      <PP color={colors[0]} d="M0 0 L25 0 L0 25 Z" />
      <PP color={colors[1]} d="M25 0 L25 25 L0 25 Z" />
    </svg>
  )
}
export default Square
