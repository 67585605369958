import * as React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import lottie from "lottie-web"

import Text from "../../components/text"
import Img from "../../components/img"
import { Link, IconLink } from "../../components/link"
import Animation from "../../components/animation"
import { color, space } from "../../theme"

export type Block1Props = {
  title: string
  copy: string
  img?: ImageType
  animation?: string
  links: (LinkType & {
    icon?: IconKeys
    bg?: ColorKeys
    shadow?: ColorKeys
    color?: ColorKeys
  })[]
}
export type Props = {
  data: Block1Props
  options?: {}
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-columns: 1fr;
  margin: 0 auto;
  padding: 0;
  justify-items: stretch;
  margin: 0 auto;
  border-top: 1px solid ${color("lightgrey")};
  ${up("tablet")} {
    grid-template-columns: 768px;
  }
  ${up("desktop")} {
    grid-template-columns: 1280px;
  }
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  grid-gap: ${space("normal")};
  padding: 0;
  ${up("tablet")} {
    padding: ${space("large")};
  }
  ${up("desktop")} {
    grid-template-columns: auto 1fr;
    justify-items: flex-start;
  }
`
const Content = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  text-align: center;
  justify-items: center;
  padding: 0 ${space("normal")};
  grid-row: 2;
  ${up("tablet")} {
    padding: 0;
    grid-row: 1;
  }
  ${up("desktop")} {
    text-align: left;
    justify-items: flex-start;
  }
  grid-gap: ${space("normal")};
`
const LinkContainer = styled.div<{ size: number }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(${p => p.size}, 45px);
  grid-gap: ${space("small")};
  ${up("tablet")} {
    grid-template-columns: repeat(${p => p.size}, auto);
    grid-template-rows: 45px;
    grid-gap: 2rem;
  }
`

const CustomLink = styled(IconLink)`
  p {
    padding-top: 0.1rem;
  }
`

const AnimationContainer = styled(Animation)`
  position: relative;
  top: -4px;
  width: 100%;
  height: auto;
  ${up("tablet")} {
    width: 620px;
    height: 480px;
  }
`

const Block1: React.FC<Props> = ({ data, options }) => (
  <Wrapper>
    <Container>
      <Content>
        <Text type="headline">{data.title}</Text>
        <Text>{data.copy}</Text>
        <LinkContainer size={data.links.length}>
          {data.links.map(({ icon, ...link }, idx) =>
            icon ? (
              <CustomLink
                key={`homepage-block1-iconlink-${idx}`}
                icon={icon}
                size="small"
                hoverColor="yellow"
                {...link}
              />
            ) : (
              <Link key={`homepage-block1-link-${idx}`} to={link.to} {...link}>
                {link.copy}
              </Link>
            )
          )}
        </LinkContainer>
      </Content>
      {data.animation && <AnimationContainer path={data.animation} />}
      {data.img && (
        <Img
          src={data.img.src}
          alt={data.img.alt}
          mw="100%"
          mh="auto"
          tw={620}
          th={480}
        />
      )}
    </Container>
  </Wrapper>
)

export default Block1
