import React from "react"
import { Helmet } from "react-helmet"

import Homepage, { HomepageProps } from "../blocks/Homepage"

const IndexPage = () => (
  <>
    <Helmet>
      <title>Paybase - Homepage</title>
      <meta property="og:title" content="Paybase - Homepage" />
    </Helmet>
    <Homepage
      data={
        {
          block1: {
            title:
              "The Most Flexible Solution for Payments, Compliance and Risk",
            copy:
              "Conceptualised, designed and engineered for businesses building the economies of tomorrow",
            animation: "/animations/homepage/all.json",
            links: [
              { to: "/why-choose-us", copy: "Explore Paybase" },
              {
                to: "https://trustshare.co",
                copy: "Download Escrow Whitepaper",
                bg: "black",
                color: "white",
                shadow: "lightblue",
                hoverColor: "black",
              },
            ],
          },
          block2: {
            squares: [
              {
                color1: "lightblue",
                color2: "yellow",
                copy: "Online marketplaces",
                to: "/who-is-it-for/marketplaces",
              },
              {
                color1: "rose",
                color2: "blue",
                copy: "Gig economy platforms",
                to: "/who-is-it-for/gig-economy",
              },
              {
                color1: "yellow",
                color2: "rose",
                copy: "Sharing economy platforms",
                to: "/who-is-it-for/sharing-economy",
              },
              {
                color1: "blue",
                color2: "green",
                copy: "FinTechs",
                to: "/who-is-it-for/fintechs",
              },
              {
                color1: "darkgreen",
                color2: "rose",
                copy: "Blockchain businesses",
                to: "/who-is-it-for/blockchain",
              },
            ],
          },
          block3: [
            {
              align: "flex-start",
              title: "Structured Flexibility",
              subtitle: "One solution, endless possibilities",
              copy:
                "Allowing you to build the business you want. No concessions, no compromises",
              link: { to: "/why-choose-us#flexibility", copy: "Read more" },
              animation:
                "/animations/why-choose-us/structured-flexibility.json",
            },
            {
              align: "flex-start",
              title: "Product Enhancement",
              subtitle: "Leverage payments to your advantage",
              copy:
                "Attract and retain more buyers and sellers through customisable loyalty programmes, reward schemes, fee structures, escrow functionality and more",
              link: { to: "/why-choose-us#product", copy: "Read more" },
            },
            {
              align: "flex-end",
              title: "Service Obsession",
              subtitle: "A partnership, not a plug-in",
              copy:
                "Always here to help, at every stage of your business journey",
              link: { to: "/why-choose-us#service", copy: "Read more" },
              animation: "/animations/why-choose-us/service-obsessed.json",
            },
            {
              align: "flex-end",
              isImageLast: true,
              title: "Financial Crime Prevention",
              subtitle: "Intelligent transaction monitoring built around you",
              copy:
                "We’re an FCA-authorised financial institution and we’re PSD2 and Level 1 PCI DSS-compliant, helping you stay one step ahead",
              link: { to: "/why-choose-us#crime", copy: "Read more" },
              animation: "/animations/why-choose-us/crime-prevention.json",
            },
          ],
          block4: [
            {
              title: "Flexible & automated payments routing",
              copy:
                "Route payments between multiple parties in your product ecosystem instantly and securely.",
              icon: "expand",
              iconBg: "darkgreen",
              anchor: "#routing",
            },
            {
              title: "Split payments",
              copy:
                "Automatically extract configurable commission fees from each transaction.",
              icon: "expand-window",
              iconBg: "purple",
              anchor: "#split",
            },
            {
              title: "Flexible account creation",
              copy:
                "Secure and instant account creation for any party in your product ecosystem, not just buyers and sellers.",
              icon: "card",
              iconBg: "red",
              anchor: "#flexible",
            },
            {
              title: "Recurring payments",
              copy: "Facilitate subscription services and scheduled payments.",
              icon: "sync",
              iconBg: "blue",
              anchor: "#recurring",
            },
            {
              title: "Escrow",
              copy:
                "Hold payments between buyers and sellers until both parties agree to funds being released. Release funds in parts as a project is completed, return a percentage to the buyer if the action is not completed within a certain timeframe and more.",
              icon: "escrow",
              iconBg: "darkgreen",
              anchor: "#escrow",
            },
            {
              title: "Banking lite",
              copy:
                "Create unique account numbers and sort codes to facilitate banking lite functionality.",
              icon: "bank",
              iconBg: "purple",
              anchor: "#banking",
            },
            {
              title: "Promotions, loyalty and rewards",
              copy:
                "Create bespoke programmes and schemes based around your users and their behaviour, giving you more control in acquiring and retaining your users.",
              icon: "star",
              iconBg: "red",
              anchor: "#reward",
            },
            {
              title: "Console",
              copy:
                "Get real-time, actionable data from across your platform. Manage user accounts, receive and act upon transaction monitoring alerts, gain insights and more.",
              icon: "window",
              iconBg: "blue",
              anchor: "#console",
            },
          ],
          block5: {
            title: "Developer friendly API_",
            copy:
              "Our team of engineers understands how difficult integrations can be, so we've designed our API to be as simple and intuitive as possible.",
            img: { src: "/homepage/console.svg", alt: "placeholder" },
            links: [
              { to: "https://docs.trustshare.io", copy: "Learn more" },
              {
                to: "/request-access",
                copy: "Get Sandbox Access",
                color: "white",
                bg: "black",
                hoverColor: "black",
                shadow: "lightblue",
              },
            ],
          },
        } as HomepageProps
      }
    />
  </>
)
export default IndexPage
