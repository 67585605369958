import * as React from "react"
import styled from "styled-components"

import Layout from "../../components/layout"

import Block1, { Block1Props } from "./block1"
import Block2, { Block2Props } from "./block2"
import Block3, { Block3Props } from "./block3"
import Block4, { Block4Props } from "./block4"
import Block5, { Block5Props } from "./block5"
import Block6 from "./block6"

export type HomepageProps = {
  block1: Block1Props
  block2: Block2Props
  block3: Block3Props
  block4: Block4Props
  block5: Block5Props
}

export type Props = {
  data: HomepageProps
  options?: {}
}

const Container = styled.div`
  display: grid;
`

const Homepage: React.FC<Props> = ({ data, options }) => (
  <Layout>
    <Container>
      <Block1 data={data.block1} />
      <Block2 data={data.block2} />
      <Block3 data={data.block3} />
      <Block4 data={data.block4} />
      <Block5 data={data.block5} />
      <Block6 />
    </Container>
  </Layout>
)

export default Homepage
