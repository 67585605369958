export type BlogProps = {
  title: string
  published: string
  author: string
  excerpt: string
  cover: ImageType
  link: LinkType
  tags: string[]
}

export const colorKeys: ColorKeys[] = [
  "blue",
  "rose",
  "purple",
  "darkgreen",
  "red",
]
export const getC = (idx: number): [ColorKeys, ColorKeys, ColorKeys] => {
  const i = idx % colorKeys.length
  const c = colorKeys[i]
  const cc = colorKeys.length > i + 1 ? colorKeys[i + 1] : colorKeys[0]
  const ccc = colorKeys.length > i + 2 ? colorKeys[i + 2] : colorKeys[1]
  return [c, cc, ccc]
}
export const randomnumber = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1)) + min
