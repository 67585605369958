import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import Text from "./text"
import { space, color } from "../theme"

const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto;
`

const LimitWiderPart = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
  ${up("tablet")} {
    grid-template-columns: 768px;
  }
  ${up("desktop")} {
    grid-template-columns: 1280px;
  }
`

const Title = styled(Text)`
  position: relative;
  padding: ${space("small")} ${space("normal")};
  margin: ${space("normal")} 0;
  margin-bottom: 0;
  text-align: center;
  ${up("desktop")} {
    text-align: left;
  }
  &:after {
    display: none;
    content: "";
    position: absolute;
    bottom: 0;
    left: 5%;
    right: 5%;
    height: 1px;
    background-color: ${color("lightgrey")};
    ${up("tablet")} {
      display: block;
    }
    ${up("desktop")} {
      left: 0;
      right: 0;
    }
  }
`
const T: React.FC<{ copy: string }> = ({ copy }) => (
  <Wrapper>
    <LimitWiderPart>
      <Title type="headline">{copy}</Title>
    </LimitWiderPart>
  </Wrapper>
)

export default T
