import * as React from "react"
import styled from "styled-components"
import { up, only } from "styled-breakpoints"

import useBreakpoints from "../../utils/use-breakpoint"

import Text from "../../components/text"
import { ArrowLink } from "../../components/link"
import Img from "../../components/img"
import { space } from "../../theme"
import Physics from "../../components/physics"
import Animation from "../../components/animation"

export type Block = {
  align: string
  title: string
  subtitle: string
  breakpoint?: string
  copy: string
  idx?: number
  isImageLast?: boolean
  link: LinkType
  animation?: string
  img?: ImageType
}
export type Block3Props = Block[]

export type Props = {
  data: Block3Props
  options?: {}
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: stretch;
  padding-bottom: ${space("large")};
  padding-top: 8rem;
  margin: 0 auto;
  ${up("tablet")} {
    padding: ${space("large")} 0;
    grid-template-columns: 768px;
  }
  ${up("desktop")} {
    grid-template-columns: 1280px;
  }
`
const Content = styled.div<{ align?: string }>`
  display: grid;
  grid-template-rows: min-content;
  grid-gap: ${space("small")};
  align-items: center;
  justify-items: center;
  justify-content: center;
  padding: 0 ${space("large")};
  ${up("tablet")} {
    padding: 0;
    align-content: flex-start;
    align-items: flex-start;
    justify-items: flex-start;
    justify-content: center;
  }
  ${only("tablet")} {
    grid-column: 2;
    grid-row: 1;
  }
  ${up("desktop")} {
    grid-column: 1;
    &:last-child {
      grid-column: 2;
    }
    grid-row: 1;
    align-content: ${p => p.align || "flex-start"};
  }
`

const BlockContainer = styled.div<{ idx: number }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${space("normal")};
  justify-items: center;

  ${up("tablet")} {
    grid-template-columns: 1fr 1fr;
  }
  ${up("desktop")} {
    position: relative;
    top: ${p => (p.idx ? p.idx * -5 - 10 : 0)}%;
    &:nth-child(2n) {
      left: -15%;
    }
    grid-template-columns: 1fr 50%;
    justify-items: flex-start;
  }
`

const BlocksContainer = styled.div<{ size: number }>`
  display: grid;
  grid-gap: ${space("normal")};
  ${up("tablet")} {
    padding: 0 4rem;
  }
  ${up("desktop")} {
    padding: 0;
  }
`

const BlockShadow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${space("normal")};

  ${up("desktop")} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0;
  }
`

const EmptyBlock = styled.div`
  display: none;
  ${up("desktop")} {
    display: block;
  }
`

const AnimationContainer = styled(Animation)`
  position: relative;
  width: 100%;
  height: auto;
  ${up("tablet")} {
    width: 360px;
    height: auto;
  }
`

const Block: React.FC<Block> = ({
  align,
  title,
  breakpoint,
  subtitle,
  copy,
  link,
  idx,
  isImageLast,
  animation,
  img,
}) => {
  const blocks = [
    img ? (
      <Img
        key={`block3-content-img-0`}
        src={img.src}
        alt={img.alt}
        mw="100%"
        mh="auto"
        tw={360}
        th={510}
      />
    ) : animation ? (
      <AnimationContainer path={animation} key={`block3-content-animation-0`} />
    ) : breakpoint !== "unknown" ? (
      <Physics key={`block3-content-physics-0`} breakpoint={breakpoint} />
    ) : null,
    <Content key={`block3-content-1`} align={align}>
      <Text color="blue">{title}</Text>
      <Text type="h4">{subtitle}</Text>
      <Text>{copy}</Text>
      <ArrowLink
        to={link.to}
        color="blue"
        hoverColor="black"
        copy={link.copy}
      />
    </Content>,
  ]
  const content = [
    <BlockContainer key={`block3-content-0`} idx={idx ? idx : 0}>
      {isImageLast && breakpoint === "desktop" ? blocks.reverse() : blocks}
    </BlockContainer>,
    <EmptyBlock key={`block3-content-1`} />,
  ]
  return (
    <BlockShadow>
      {typeof idx !== "undefined" && idx % 2 === 0
        ? content
        : content.reverse()}
    </BlockShadow>
  )
}

const Block3: React.FC<Props> = ({ data, options }) => {
  const breakpoint = useBreakpoints({
    defaultValue: "unknown",
    config: { mobile: 0, tablet: 767, desktop: 1145 },
  })

  return (
    <Wrapper>
      <BlocksContainer size={data.length}>
        {data.map((block, idx) => (
          <Block
            key={`block_${idx}`}
            idx={idx}
            {...block}
            breakpoint={breakpoint}
          />
        ))}
      </BlocksContainer>
    </Wrapper>
  )
}

export default Block3
